//
// Common: variables: fonts
//
// @author: Lemone - lemone.com
//
$enable-responsive-font-sizes: false;

$font-family-base: "AxiformaLight", sans-serif;
$font-weight-base: 200;

$headings-font-family: "AxiformaExtraBold", sans-serif;
$headings-font-weight: 900;

$font-size-base: 16px;
$font-size-xl: 20 / 16 + rem;
$font-size-md: 16 / 16 + rem;
$font-size-sm: 15 / 16 + rem;

$line-height-base: (28 / 18);
$line-height-sm: (24 / 15);

$headings-line-height: 1.1;

$h1-font-size: (58 / 16) + rem;
$h2-font-size: (50 / 16) + rem;
$h3-font-size: (36 / 16) + rem;
$h4-font-size: (32 / 16) + rem;
$h5-font-size: (22 / 16) + rem;
$h6-font-size: (18 / 16) + rem;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$display-line-height: (78 / 68);
$display-font-weight: 700;
$display1-size: (134 /16) + rem;
$display2-size: (78 /16) + rem;
$display3-size: (64 /16) + rem;

$display-font-sizes: (
  1: $display1-size,
  2: $display2-size,
);

$paragraph-margin-bottom: 40px;
$headings-margin-bottom: 50px;
