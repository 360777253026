.express-service {
  position: relative;

  &::before {
    content: url("../images/express-bg-illustration-left.svg");
    position: absolute;
    right: 38vw;
    top: 0;
    z-index: -1;

    @include media-breakpoint-up(lg) {
      right: calc(50% + 180px);
    }
  }

  &::after {
    content: url("../images/express-bg-illustration-right.svg");
    left: 38vw;
    position: absolute;
    top: 0;
    z-index: -1;

    @include media-breakpoint-up(lg) {
      left: calc(50% + 180px);
    }
  }
}
