$input-btn-font-size: $font-size-base;

$btn-font-weight: bold;
$btn-font-family: $headings-font-family;
$btn-padding-y: 20px;
$btn-padding-x: 20px;
$btn-line-height: 1;
$btn-border-radius: 10px;
$btn-border-width: 0;

$btn-focus-width: 0;
$btn-focus-box-shadow: 0;

$btn-hover-bg-shade-amount: 0;
$btn-hover-bg-tint-amount: 0;
$btn-hover-border-shade-amount: 0;
$btn-hover-border-tint-amount: 0;
$btn-active-bg-shade-amount: 0;
$btn-active-bg-tint-amount: 0;
$btn-active-border-shade-amount: 0;
$btn-active-border-tint-amount: 0;
