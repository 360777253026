//
// Common: variables: colors
//
// @author: Lemone - lemone.com
//

$white: #fff;
$black: #000;

$blue: #00457b;
$green: #439d4c;
$midnight: #00223d;
$grey: #6b8198;
$light-grey: #e5ecf1;
$off-white: #ecf5ed;
$orange: #ff5b02;
$dark-orange: #db4d01;

$body-color: $midnight;
$body-bg: $white;
$headings-color: $midnight;
$link-color: $orange;

$theme-colors: (
  "primary": $midnight,
  "secondary": $orange
);
