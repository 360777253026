.header {
  max-width: 100vw;
  padding: 25px 0 38px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    padding: 50px 0 38px;
  }
}
