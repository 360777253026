.nav {
  align-items: center;
  height: 100%;
  // &-link {
  //   font-family: $link-font-family;
  // }

  // &-item {
  //   display: flex;
  //   align-items: center;

  //   .btn {
  //     @include media-breakpoint-up(lg) {
  //       margin-left: 15px;
  //     }
  //   }

  //   & + & {
  //     margin-top: 10px;

  //     @include media-breakpoint-up(lg) {
  //       margin-top: 0;
  //     }
  //   }

  //   &--hide-for-desktop {
  //     @include media-breakpoint-up(lg) {
  //       display: none;
  //     }
  //   }
  // }
  .menu-list {
    align-items: center;
    display: flex;

    .menu-list__item + .menu-list__item {
      padding-left: 15px;

      @include media-breakpoint-up(lg) {
        padding-left: 30px;
      }
    }

    &__item {
      display: inline-block;

      .menu-link {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
          .text {
            display: none;
          }
        }
      }

      .dropdown {
        &-menu {
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);

          .dropdown-item {
            svg {
              box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }
}
