.footer {
  background-color: $midnight;
  margin-top: -340px;
  padding: 400px 0 20px;

  @include media-breakpoint-up(lg) {
    margin-top: -150px;
    padding: 250px 0 100px;
  }

  &-socials {
    display: flex;
    margin-bottom: 60px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 95px;
    }

    .btn {
      margin-left: 30px;
    }

    .btn + .btn {
      margin-left: 20px;
    }
  }

  &-list {
    margin: 0;

    @include media-breakpoint-down(lg) {
      margin-bottom: 40px;
    }

    &__item {
      .link {
        border-bottom: 1px solid $grey;
        color: $grey;
        font-family: $font-family-base;
        font-weight: $font-weight-base;
        margin-left: 15px;
        padding-bottom: 2px;
        text-decoration: none;

      }

      & + & {
        margin-top: 14px;
      }
    }
  }

  &-heading {
    align-items: center;
    display: flex;
    margin-bottom: 15px;

    svg {
      margin-right: 10px;
    }
  }

  &__title {
    color: $white;
    font-size: $h6-font-size;
    margin-bottom: 0;
  }
}
