.service {
  background-color: $midnight;
  background-position: center right;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 10px;
  padding: 30px 30px 20px;

  @include media-breakpoint-up(lg) {
    height: 100%;
    margin-bottom: 0;
    padding: 45px 35px;
  }

  &__title {
    color: $white;
    font-size: $font-size-xl;
    margin: 20px 0;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      margin: 25px 0;
    }
  }

  &--express {
    background-image: url("../images/express-delivery-bg.svg");

    svg {
      @include media-breakpoint-down(lg) {
        height: 30px;
      }
    }
  }

  &--international {
    background-image: url("../images/international-bg.svg");

    svg {
      @include media-breakpoint-down(lg) {
        height: 32px;
      }
    }
  }
}
