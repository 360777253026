.team {
  & + & {
    margin-top: 40px;

    @include media-breakpoint-up(lg) {
      margin-top: 85px;
    }
  }

  .team__title {
    margin: 25px 0 22px;

    @include media-breakpoint-up(lg) {
      margin: 25px 0 32px;
    }
  }

  .flag {
    box-shadow: 0 2px 20px 0 rgba(0, 34, 61, 0.2);

    @include media-breakpoint-down(lg) {
      transform: scale(0.75);
    }
  }

  ul > li:first-of-type {
    margin-right: 32px;
  }

  &-member {
    margin: 19px 0 0;

    &__image {
      aspect-ratio: 1 / 1;
      border-radius: 100%;
      height: auto;
      object-fit: cover;
      width: 100%;
    }

    &__title {
      font-size: $font-size-base;
      margin: 20px 0 0;
      text-align: center;
    }

    &__job-description {
      color: $grey;
      display: block;
      margin-top: 5px;
      text-align: center;
    }
  }
}
