//
// Component: button
//
// @author: Lemone - lemone.com
//

@import "~bootstrap/scss/buttons";

.btn {
  color: $white;
  transition: background-color ease-in-out 0.15s;

  svg {
    margin-right: 7px;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $dark-orange;
    color: $white;
  }

  &-social {
    align-items: center;
    border: 1px solid $orange;
    border-radius: 10px;
    display: flex;
    height: 60px;
    justify-content: center;
    padding: 0;
    width: 60px;

    svg {
      margin: 0;
    }
  }

  &-dropdown {
    align-items: center;
    background-color: $white;
    border: 1px solid transparentize($color: $midnight, $amount: 0.8);
    border-radius: 10px;
    color: $grey;
    display: flex;
    font-size: $font-size-md;
    line-height: 1;
    padding: 10px;
    text-decoration: none;

    &::after {
      content: url("../images/chevron.svg");
      line-height: 0;
    }

    &:active,
    &:focus,
    &:hover {
      color: $grey;
    }

    svg {
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
    }

    &__text {
      display: none;
      padding-right: 10px;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }

    &.show::after {
      transform: rotate(180deg);
    }
  }
}
