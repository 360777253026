.visual {
  height: 307px;
  left: -20px;
  position: absolute;
  top: 52px;
  width: auto;

  @include media-breakpoint-up(lg) {
    height: 614px;
    left: -112px;
    top: 150px;
  }


  &-background {
    @include media-breakpoint-down(lg) {
      margin-top: -20px;
      position: relative;
      z-index: -1;

      svg {
        height: 376px;
        margin-left: -20px;
        width: 529px;
      }
    }
  }
}
