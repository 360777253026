//
// Layout: Grid
//
// @author: Lemone - lemone.com
//

@import "~bootstrap/scss/grid";

.container {
  @include media-breakpoint-down(lg) {
    padding: 0 15px;
  }
}
