.section {
  &-usps {
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding: 0 0 130px;
    }

    &__title {
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }
    }
  }

  &-services {
    padding: 0 0 40px;

    @include media-breakpoint-up(lg) {
      padding: 0 0 12px;
    }

    &__title {
      @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
      }
    }
  }

  &-content {
    padding: 0 0 50px;

    @include media-breakpoint-up(lg) {
      padding: 0 0 130px;
    }

    &__title {
      @include media-breakpoint-down(lg) {
        margin-bottom: 34px;
      }
    }
  }

  &-express-service {
    padding: 0 0 30px;

    @include media-breakpoint-up(lg) {
      padding: 0 0 130px;
    }

    &__title {
      @include media-breakpoint-down(lg) {
        margin-bottom: 15px;
      }
    }
  }

  &-teams {
    padding: 0 0 60px;

    @include media-breakpoint-up(lg) {
      padding: 0 0 75px;
    }
  }
}
