.hero {
  padding: 0 0 50px;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 0 0 130px;
  }

  &::before {
    content: url("../images/hero-bg-small.svg");
    line-height: 0;
    position: absolute;
    right: 7vw;
    top: -100px;

    @include media-breakpoint-up(sm) {
      right: calc(50vw - 130px);
    }

    @include media-breakpoint-up(md) {
      right: calc(50vw - 80px);
    }

    @include media-breakpoint-up(lg) {
      content: url("../images/hero-bg.svg");
      left: calc(50% + 376px);
      right: -12px;
      top: -130px;
    }
  }

  &-content {
    margin-top: -20px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      padding-top: 90px;
    }

    &__title {
      @include media-breakpoint-down(lg) {
        font-size: $h3-font-size;
        margin-bottom: 30px;
      }

      @include media-breakpoint-up(lg) {
        font-size: $h2-font-size;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h1-font-size;
      }
    }
  }

  &-visual {
    position: absolute;

    @include media-breakpoint-down(lg) {
      position: relative;
    }

    img {
      height: auto;
      left: -180px;
      top: 0;
      width: 1440px;

      @include media-breakpoint-down(lg) {
        height: 344px;
        margin-left: -43px;
        width: unset;
      }
    }
  }
}
