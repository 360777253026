//
// Common: base
//
// @author: Brian Trommelen - lemone.com
//

// @import "~bootstrap/scss/bootstrap-reboot";

html {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

main {
  overflow: hidden;
  padding-top: 130px;

  @include media-breakpoint-up(lg) {
    padding-top: 155px;
  }
}

*:focus-visible {
  outline: none;
}

main {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.83px;
}

h1 {
  @include media-breakpoint-down(xl) {
    font-size: $h2-font-size;
  }
}

h2,
h3 {
  @include media-breakpoint-down(xl) {
    font-size: $h4-font-size;
  }
}

p {
  color: $grey;
}

.link {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  text-decoration: none;

  svg {
    margin-left: 7px;
  }

  &-alt {
    svg {
      margin: 0 7px 0 0;
    }
  }
}

.brand-logo svg {
  @include media-breakpoint-down(lg) {
    width: 140px;
  }
}

.whatsapp {
  align-items: center;
  background-color: #2bb826;
  border-radius: 10px;
  bottom: 15px;
  display: flex;
  height: 45px;
  justify-content: center;
  position: fixed;
  right: 15px;
  transition: background-color ease-in-out 0.15s;
  width: 45px;
  z-index: 10;

  @include media-breakpoint-up(lg) {
    bottom: 45px;
    height: 60px;
    right: 45px;
    width: 60px;

    svg {
      width: unset;
    }
  }

  svg {
    width: 32px;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: darken($color: #2bb826, $amount: 20%);
    color: $white;
  }
}
