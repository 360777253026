@import "~bootstrap/scss/forms";

.form {
  background-color: $white;
  border-radius: 40px;
  box-shadow: 0 2px 300px 0 rgba(0, 34, 61, 0.17);
  padding: 42px 20px 60px;

  @include media-breakpoint-up(lg) {
    padding: 100px;
  }

  &-intro {
    margin: auto;
    max-width: 555px;
    text-align: center;
  }

  &-wrapper {
    position: relative;
    z-index: 1;

    &::before {
      content: url("../images/form-bg-illustration.svg");
      position: absolute;
      right: calc(100% - 100px);
      top: 160px;
      z-index: -1;

      @include media-breakpoint-up(lg) {
        right: calc(100% - 140px);
      }
    }

    &::after {
      bottom: -300px;
      content: url("../images/form-bg-illustration.svg");
      left: calc(100% - 100px);
      position: absolute;
      z-index: -1;

      @include media-breakpoint-up(lg) {
        left: calc(100% - 140px);
      }
    }
  }

  label:not(.form-check-label) {
    color: $midnight;
    font-family: $headings-font-family;
    font-size: $font-size-sm;
    font-weight: $headings-font-weight;
  }

  .form-label {
    &--arrow {
      align-items: center;
      background-color: $white;
      display: flex;
      position: relative;
      white-space: nowrap;
      width: 100%;

      &::after {
        background-color: $midnight;
        content: '';
        display: inline-block;
        height: 1px;
        margin: 0 -12px 0 12px;
        right: -14px;
        width: 100%;

        @include media-breakpoint-down(lg) {
          content: none;
        }
      }

      &::before {
        content: url("../images/icon-arrow-head.svg");
        position: absolute;
        right: -14px;

        @include media-breakpoint-down(lg) {
          content: none;
        }
      }
    }
  }

  input,
  textarea {
    margin-bottom: 30px;
  }

  .row + .row {
    margin-top: 30px;
  }

  &-information {
    &__title {
      align-items: center;
      display: flex;
      font-size: $h5-font-size;
      line-height: 30px;
      margin-bottom: 25px;
      padding-left: 40px;
      position: relative;

      &::before {
        left: 0;
        line-height: 0;
        position: absolute;
      }

      .form-information--information &::before {
        content: url("../images/icon-information.svg");
      }

      .form-information--freight &::before {
        content: url("../images/icon-freight.svg");
      }

      .form-information--destination &::before {
        content: url("../images/icon-destination.svg");
      }

      &::after {
        background-color: transparentize($color: $grey, $amount: 0.8);
        content: '';
        height: 1px;
        margin: 5px 0 0 12px;
        right: 0;
        width: 100%;
      }
    }

    svg {
      width: 40px;
    }
  }
}
