//
// Common: Fonts
//
// @author: Lemone - lemone.com
//

$fontpath: "../fonts/";

// Font definitions

@font-face {
  font-family: "AxiformaExtraBold";
  font-weight: 900;
  font-style: normal;
  src: url("#{$fontpath}AxiformaExtraBold.woff2") format("woff2"),
    url("#{$fontpath}AxiformaExtraBold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "AxiformaLight";
  font-weight: 200;
  font-style: normal;
  src: url("#{$fontpath}AxiformaLight.woff2") format("woff2"),
    url("#{$fontpath}AxiformaLight.woff") format("woff");
  font-display: swap;
}
