//
// Common: variables
//
// @author: Lemone - lemone.com
//

// Bootstrap variables

// Base
@import "colors";
@import "fonts";
@import "buttons";
@import "dropdown";
@import "grid";
@import "link";
@import "forms";
@import "nav";

$container-padding-x: 0;
$enable-caret: false;
$dropdown-padding-y: 0;
$dropdown-padding-x: 0;
$dropdown-item-padding-y: 10px;
$dropdown-item-padding-x: 15px;
$dropdown-min-width: 170px;
