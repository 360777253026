.usp {
  &-list {
    @include make-row;
    padding: 0;

    &__item {
      @include make-col(12);
      align-items: center;
      display: flex;
      flex-direction: row;
      list-style-type: none;
      margin-bottom: 30px;
      padding: 0 16px;

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      svg {
        margin-right: 10px;
        width: 54px;

        @include media-breakpoint-up(lg) {
          min-width: 68px;
          width: auto;
        }
      }

      h3 {
        font-size: $font-size-base;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          font-size: $h6-font-size;
        }
      }
    }
  }
}
