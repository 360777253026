//
// Stylesheet
//
// @author: Lemone - lemone.com
//

// WIP: Using Bootstrap 5.0 from now on
// @NOTE: Needs correct .sassrc config

// @import "common/common";
// @import "components/components";
// @import "layout/layout";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "common/common";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/close";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utililies.scss`
@import "~bootstrap/scss/utilities/api";

// 6. Import custom styles, components en layouts
@import "components/components";
@import "layout/layout";

div.parrot {
  position:fixed;    
  z-index: 100; 

  img {
    width: 150px;
    height: 150px;
  }
}

.parrot-text {
  background-color: white;
  border: 2px solid black;
  padding: 10px;
  margin-bottom: 0;
  text-align: center;
}
