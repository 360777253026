.card {
  background-color: $white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 37px 40px 47px;
  position: relative;

  @include media-breakpoint-down(lg) {
    height: calc(100% - 20px);
    margin-bottom: 20px;
  }

  &::before {
    border-radius: 20px;
    bottom: 0;
    box-shadow: 0 0 60px 0 rgba(0, 34, 61, 0.1);
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &__visual {
    height: auto;
    margin-bottom: 37px;
    max-width: 100%;
  }

  &-information {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &::before {
      background-color: $light-grey;
      content: '';
      height: 1px;
      left: 20px;
      position: absolute;
      right: 20px;
    }

    &__title {
      font-size: $h5-font-size;
      margin: 40px 0 25px;
    }

    &__spec {
      color: $grey;
      display: block;
    }

    .link {
      margin-top: 30px;
    }
  }
}
